import { localStorageService } from '@client/utils/localStorageService'
import dayjs from 'dayjs'

import { PopoverBannerStorage } from './PopupBanner.types'

const POPOVER_STORAGE_KEY = 'popover'

export const setPopoverBannerInitialStorage = () =>
  localStorageService.set(POPOVER_STORAGE_KEY, {
    collapse: false,
    dismissed: null
  })

export const setPopoverBannerCollapsed = ({ value }: { value: boolean }) => {
  const previousState = localStorageService.get(POPOVER_STORAGE_KEY)

  return localStorageService.set(POPOVER_STORAGE_KEY, {
    collapse: value,
    dismissed: previousState?.dismissed
  })
}

export const setPopoverBannerDismissed = () => {
  const today = dayjs()
  const dismissed = today.add(1, 'day').toISOString()

  localStorageService.set(POPOVER_STORAGE_KEY, { dismissed })
}

export const getPopoverBannerStorage = (): PopoverBannerStorage =>
  localStorageService.get(POPOVER_STORAGE_KEY)
