export const PopupBannerMock = {
  title: 'Дорогие читатели!',
  text:
    'Уже больше двух лет мы не можем получать деньги из России — хотя именно там живет большинство наших читателей. Сперва нам казалось, что справиться с этим не получится.',
  buttonText: 'Поддержать',
  buttonUrl: 'https://support.meduza.io/',
  buttonColor: '#fff',
  buttonTextColor: '#000',
  panel: 'Закрытая панель с коротким текстом',
  textColor: '#fff',
  backgroundColor: '#000'
}
