import React from 'react'

import { PopupBannerProps } from './PopupBanner.types'

import styles from './PopoverBannerContent.module.css'

export const PopoverBannerContent = ({ content }: PopupBannerProps) => {
  const { title, text, buttonText, buttonUrl } = content

  return (
    <div className={styles.root}>
      <div className={styles.main}>
        <h3
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <a
          className={styles.button}
          href={buttonUrl}
          target="_blank"
          type="button"
          rel="noopener noreferrer"
        >
          {buttonText}
        </a>
      </div>
    </div>
  )
}
