import React, { useEffect, useState } from 'react'
import { makeClassName } from '@client/utils/makeClassName'
import dayjs from 'dayjs'

import {
  getPopoverBannerStorage,
  setPopoverBannerCollapsed,
  setPopoverBannerDismissed,
  setPopoverBannerInitialStorage
} from './helpers'
import { PopoverBannerContent } from './PopoverBannerContent'

import styles from './PopoverBanner.module.css'

interface PopupBannerProps {
  content: {
    title: string
    text: string
    buttonText: string
    buttonUrl: string
    buttonColor: string
    buttonTextColor: string
    panel: string
    textColor: string
    backgroundColor: string
  }
}

export const PopupBanner = ({ content }: PopupBannerProps) => {
  const [isInitial, setIsInitial] = useState(false)
  const [shouldRender, setShouldRender] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState(false)

  const handleDismiss = () => {
    setPopoverBannerDismissed()

    setShouldRender(false)
  }

  const handleCollapse = () => {
    setPopoverBannerCollapsed({ value: true })
    setIsCollapsed(true)
  }

  useEffect(() => {
    if (isInitial) {
      return
    }

    setIsInitial(true)

    const storage = getPopoverBannerStorage()
    const today = dayjs()

    if (!storage) {
      setPopoverBannerInitialStorage()

      return setShouldRender(true)
    }

    const shouldRender = today.isAfter(dayjs(storage.dismissed))

    setShouldRender(shouldRender)
    setIsCollapsed(storage.collapse)
  }, [isInitial])

  const style = {
    '--textColor': content.textColor,
    '--backgroundColor': content.backgroundColor,
    '--buttonColor': content.buttonColor,
    '--buttonTextColor': content.buttonTextColor
  } as React.CSSProperties

  return (
    <div
      className={makeClassName([
        [styles.root, true],
        [styles.isRendered, shouldRender],
        [styles.isCollapsed, shouldRender && isCollapsed]
      ])}
      style={style}
    >
      <div className={styles.container}>
        <div className={styles.panel}>
          <button
            className={styles.dismiss}
            onClick={handleCollapse}
            type="button"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M15 1L1 15M1 1l14 14" stroke="currentColor" />
            </svg>
          </button>
          <PopoverBannerContent content={content} />
        </div>

        <div className={styles.notifyPanel}>
          <button
            className={styles.notify}
            onClick={handleDismiss}
            type="button"
          >
            {content.panel}
            <span className={styles.expand}>Dismiss</span>
          </button>
        </div>
      </div>
    </div>
  )
}
